<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getCoupons"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <button class="btn btn-success btn-sm" @click="showImport">
                    <i class="uil uil-import"></i> Import
                  </button>
                  <button class="btn btn-primary btn-sm mr-4 ml-2" @click="newCampaign">
                    <i class="uil uil-plus"></i> New Campaign
                  </button>
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getCoupons"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingCoupons">
              <div class="table-responsive mb-0">
                <b-table
                  :items="coupons"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :sort-direction="datatable.queries.sort_direction"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(name)="data">
                    <div class="d-flex align-items-center">
                      <span class="cursor-pointer pr-2" :class="{ 'text-danger': hasNoKeywords(data.item) }">{{ data.item.name }}</span>
                      <span v-if="hasNoKeywords(data.item)" v-b-tooltip.hover v-b-tooltip.hover.top title="No keyword is assigned to this campaign!" class="text-danger font-italic cursor-pointer pr-2"><feather type="alert-circle" class="icon-dual-danger" /></span>
                    </div>
                  </template>
                  <template v-slot:cell(type)="data">
                    <span class="text-capitalize">{{ formatType(data.item.widget ? 'loopwidget' : data.item.type) }}</span>
                  </template>
                  <template v-slot:cell(traffic)="data">
                    <div style="min-width: 190px">
                      <button :id="`button-mobile-visitor-${data.item.id}`" class="btn btn-primary">
                        {{ data.item.mobile_traffic }}
                      </button>
                      <b-tooltip :target="`button-mobile-visitor-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.mobile_traffic }}</h2>
                        <span>Mobile Visitors</span>
                      </b-tooltip>

                      <button :id="`button-desktop-visitor-${data.item.id}`" class="btn btn-dark ml-1">
                        {{ data.item.desktop_traffic }}
                      </button>
                      <b-tooltip :target="`button-desktop-visitor-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.desktop_traffic }}</h2>
                        <span>Desktop Visitors</span>
                      </b-tooltip>

                      <button :id="`button-total-visitor-${data.item.id}`" class="btn btn-success ml-1">
                        {{ data.item.mobile_traffic + data.item.desktop_traffic  }}
                      </button>
                      <b-tooltip :target="`button-total-visitor-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.mobile_traffic + data.item.desktop_traffic }}</h2>
                        <span>Total Visitors</span>
                      </b-tooltip>
                    </div>

                  </template>
                  <template v-slot:cell(conversions)="data">
                    <div style="min-width: 210px">
                      <button :id="`button-mobile-conversion-${data.item.id}`" class="btn btn-primary">
                        {{ data.item.mobile_conversion_rate }}%
                      </button>
                      <b-tooltip :target="`button-mobile-conversion-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.mobile_conversion_rate }}%</h2>
                        <span>Conversion Rate From Mobile Traffic</span>
                      </b-tooltip>

                      <button :id="`button-desktop-conversion-${data.item.id}`" class="btn btn-dark ml-1">
                        {{ data.item.desktop_conversion_rate }}%
                      </button>
                      <b-tooltip :target="`button-desktop-conversion-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.desktop_conversion_rate }}%</h2>
                        <span>Conversion Rate From Desktop Traffic</span>
                      </b-tooltip>

                      <button :id="`button-total-conversion-${data.item.id}`" class="btn btn-success ml-1">
                        {{ data.item.total_conversion_rate }}%
                      </button>
                      <b-tooltip :target="`button-total-conversion-${data.item.id}`" placement="top">
                        <h2 class="text-center" style="color: #fff">{{ data.item.total_conversion_rate }}%</h2>
                        <span>Conversion Rate From Total Traffic</span>
                      </b-tooltip>
                    </div>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <div class="table-actions-group" style="min-width: 250px">
                      <b-button
                        v-if="data.item.type === 'popup' || data.item.widget || data.item.type === 'modal'"
                        variant="primary"
                        title="Show script"
                        class="ml-1 mr-1"
                        :disabled="loadingView === data.item.id"
                        @click="showSrcipt(data.item)"
                      >
                        <b-spinner v-if="loadingView === data.item.id" small />
                        <i v-else class="uil uil-code"></i>
                      </b-button>
                      <a v-else
                        :href="`${domainUrl}/pages/${data.item.link}/${user.business.id}`"
                        target="_blank"
                        class="btn btn-primary ml-1 mr-1">
                        <i class="uil uil-eye"></i>
                      </a>
                      <router-link
                        v-if="data.item.type === 'modal'"
                        :to="{
                          name: 'business.popup.edit',
                          params: { id: data.item.id },
                        }"
                        class="btn btn-outline-dark"
                      >
                        <i class="uil uil-edit"></i> Edit
                      </router-link>
                      <router-link
                        v-else
                        :to="{
                          name: data.item.type === 'popup' ? 'business.coupons.edit' : 'business.landings.edit',
                          params: { id: data.item.id },
                        }"
                        class="btn btn-outline-dark"
                      >
                        <i class="uil uil-edit"></i> Edit
                      </router-link>
                      <b-dropdown
                        variant="outline-dark"
                        right
                      >
                      <template v-slot:button-content>
                        <i class="uil uil-angle-down"></i>
                      </template>
                        <b-dropdown-item href="#" @click="share(data.item)">
                          <i class="uil uil-share-alt"></i> Share
                        </b-dropdown-item>
                        <b-dropdown-item v-if="data.item.type === 'vote'" href="#" @click="viewResult(data.item)">
                          <i class="uil  uil-sitemap"></i> View Result
                        </b-dropdown-item>
                        <b-dropdown-item v-if="['landing', 'loop', 'vote'].includes(data.item.type)" :to="{ name: 'business.coupons.setting', params: { id: data.item.id } }">
                          <i class="uil uil-cog"></i> Meta Data
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="clone(data.item)">
                          <i class="uil uil-copy"></i> Clone
                        </b-dropdown-item>
                        <b-dropdown-item href="#"
                          :disabled="loadingRemove === data.item.id"
                          @click="destroy(data.item)">
                          <b-spinner v-if="loadingRemove === data.item.id" small />
                          <span v-else class="text-danger">
                            <i class="uil uil-trash"></i> Delete
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalCoupons }} campaigns
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalCoupons"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showNewCampaign" title="New campaign" size="lg">
      <div class="row text-center">
        <div class="col-4">
          <router-link :to="{ name: 'business.coupons.create' }">
            <img
              style="height:120px;display:block;margin:0 auto;"
              src="@assets/images/website-widget.webp"
            />
            <h4 class="h4">Website Widget</h4>
          </router-link>
        </div>
        <div class="col-4">
          <router-link :to="{ name: 'business.landings.create' }">
            <img
              style="height:120px;display:block;margin:0 auto;"
              src="@assets/images/landing.webp"
            />
            <h4 class="h4">Landing Page</h4>
          </router-link>
        </div>
        <div class="col-4">
          <router-link :to="{ name: 'business.popup.create' }">
            <img
              style="height:120px;display:block;margin:0 auto;"
              src="@assets/images/popup.webp"
            />
            <h4 class="h4">Pop up</h4>
          </router-link>
        </div>
        <div v-if="loopActive" class="col-4">
          <router-link :to="{ name: 'business.landings.create', query: { loop: true } }">
            <img
              style="height:120px;display:block;margin:0 auto;"
              src="@assets/images/loop.webp"
            />
            <h4 class="h4">Loop</h4>
          </router-link>
        </div>
        <div v-else class="col-4" >
           <div class="image">
            <div class="overlay">
            </div>
            <button class="upgrade-btn" @click="upgradeModal">
                Upgrade
            </button>
            <h4 class="h4">Loop</h4>
          </div>
        </div>
        <div v-if="enableVote" class="col-4">
          <router-link :to="{ name: 'business.landings.create', query: { vote: true } }">
            <img
              style="height:120px;display:block;margin:0 auto;"
              src="@assets/images/vote.webp"
            />
            <h4 class="h4">Vote</h4>
          </router-link>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showNewCampaign = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete a Campaign">
      <p v-if="selectedCoupon"
        >Are you sure you want to delete campaign "{{ selectedCoupon.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="isShowCloneModal" title="Clone">
      <p>Are you sure you want to clone this item?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowCloneModal = false"
          >Cancel</b-button
        >
        <b-button variant="primary" @click="handleClone">Clone</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showScriptModal" title="Script" size="lg">
      <h3>Copy the widget code and install it on Your website</h3>
      <p
        >Copy the code below and paste it before the closing
        <span class="text-red">&lt;/body&gt;</span> tag in your website code.</p
      >
      <div>
        <textarea
          ref="scriptText"
          v-model="couponScript"
          name="code"
          cols="30"
          rows="6"
          class="form-control"
          readonly
          @click="copyScript"
        ></textarea>
        <div class="text-right mt-1">
          <a href="javascript:void(0)" @click="copyScript">
            Copy the code
          </a>
        </div>
      </div>
      <h5>Send the guidelines on widget installation to Your programmer</h5>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="emailForm">
        <form @submit.prevent="handleSubmit(sendEmail)">
          <div class="form-row" style="justify-content: center;">
            <div class="col-auto" style="min-width:300px">
              <TextInput
                v-model="email"
                name="email"
                rules="required|email"
                placeholder="email@example.com"
              />
            </div>
            <div class="col-auto">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="loadingSend || invalid"
                @click="sendEmail"
              >
                <b-spinner v-if="loadingSend" small />
                <span v-else>Send</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="copyScript"
          ><i class="uil uil-copy"></i> Copy</b-button
        >
        <b-button variant="light" @click="showScriptModal = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="showUpgradeModal" title="Upgrade Account">
      <p>
        Loop feature is locked at the moment. Please contact 
          <a :href="'mailto:' + agencyEmail">{{ agencyEmail }}</a> to get the Loop Access.
      </p>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="showUpgradeModal = false"
          > OK</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="showShareModal" title="Share campaign" size="lg">
      <div>
        <textarea
          ref="shareLink"
          v-model="shareLink"
          cols="30"
          rows="6"
          class="form-control"
          readonly
          @click="copyLink"
        ></textarea>
        <div class="text-right mt-1">
          <a href="javascript:void(0)" @click="copyLink">
            Copy the link
          </a>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="copyLink"
          ><i class="uil uil-copy"></i> Copy</b-button
        >
        <b-button variant="light" @click="showShareModal = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>
    <b-modal v-model="isShowImport" title="Import campaign" size="lg">
      <div>
        <TextInput v-model="importLink" label="Link" />
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="!importLink" @click="importCampaign">
          <b-spinner v-if="loadingImport" small />
          <span v-else>
            <i class="uil uil-import"></i> Import
          </span>
        </b-button>
        <b-button variant="light" @click="isShowImport = false"
          >Cancel</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.table-responsive {
  overflow: unset;
}
</style>

<script>
import Vue from 'vue'
export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'name', sortable: true },
          { key: 'type' },
          { label: 'Keyword', key: 'keyword' },
          { label: 'Opt Ins', key: 'opt_ins' },
          { label: 'Traffic', key: 'traffic' },
          { label: 'Conversions', key: 'conversions' },
          { key: 'actions' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          type: 'popup',
          sort_by: 'id',
          sort_desc: true,
          sort_direction: 'desc',
        },
      },
      loadingView: null,
      loadingCoupons: false,
      loadingRemove: false,
      loadingSend: false,
      selectedCoupon: null,
      showDeleteModal: false,
      showUpgradeModal: false,
      showScriptModal: false,
      couponScript: null,
      email: null,
      showNewCampaign: false,
      loadingClone: null,
      isShowCloneModal: false,
      showShareModal: false,
      loadingImport: false,
      isShowImport: false,
      importLink: null,
      shareLink: null,
    }
  },

  computed: {
    enableVote() {
      return this.agency && this.agency.use_vote_campaign 
    },

    loopActive() {
      return this.user && this.user.business && this.user.business.is_active_loop && 
      this.user.business.agency && this.user.business.agency.is_enabled_loop
    },

    coupons() {
      return this.$store.getters['coupon/all'] || []
    },

    totalCoupons() {
      return this.$store.getters['coupon/total']
    },

    config() {
      return this.$store.getters['app/config']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    agency() {
      return this.user && this.user.business && this.user.business.agency
    },

    agencyEmail() {
      return this.agency ? this.agency.email : ''
    },

    domainUrl() {
      let url = '';
      if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8080'
      }
      const  { whitelabel } = this.config
      if (this.config.custom_domain && whitelabel && whitelabel.is_ssl_cert_exists && whitelabel.is_vhost_exists) {
        url = `https://${this.config.custom_domain}`;
      } else {
        url = `https://${this.config.subdomain}.${this.config.whitelabel.domain}`
      }
      return url;
    }
  },

  mounted() {
    this.getCoupons()
  },

  methods: {
    hasNoKeywords(item) {
      return !item.keyword
    },

    formatType(type) {
      if (type === 'popup')
        return 'widget'
      return type
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getCoupons()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getCoupons()
    },

    getCoupons() {
      this.loadingCoupons = true
      this.$store
        .dispatch('coupon/getAll', this.datatable.queries)
        .then(() => {
          this.loadingCoupons = false
        })
        .catch(() => {
          this.loadingCoupons = false
        })
    },

    showSrcipt(coupon) {
      this.selectedCoupon = coupon
      this.loadingView = coupon.id
      this.$store
        .dispatch('coupon/getScript', coupon.widget ? coupon.widget.id : coupon.id)
        .then((script) => {
          this.couponScript = script
          this.showScriptModal = true
          this.loadingView = null
        })
        .catch(() => {
          this.loadingView = null
        })
    },

    destroy(coupon) {
      this.showDeleteModal = true
      this.selectedCoupon = coupon
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedCoupon.id
    
      this.$store
        .dispatch(this.selectedCoupon.type === 'popup' ? 'coupon/destroy' : 'landing/remove', this.selectedCoupon.id)
        .then(() => {
          this.loadingRemove = null
          this.getCoupons()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    copyScript() {
      this.$refs.scriptText.select()
      this.$refs.scriptText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    sendEmail() {
      this.loadingSend = true

      this.$store
        .dispatch('coupon/sendEmail', {
          couponId: this.selectedCoupon.id,
          email: this.email,
        })
        .then(() => {
          this.loadingSend = false
          this.email = null
          this.$refs.emailForm.reset()
        })
        .catch(() => {
          this.loadingSend = false
        })
    },

    newCampaign() {
      this.showNewCampaign = true
    },

    upgradeModal() {
      this.showUpgradeModal = true
    },

    clone(coupon) {
      this.isShowCloneModal = true
      this.selectedCoupon = coupon
    },

    handleClone() {
      this.loadingClone = true

      this.$store
        .dispatch('coupon/clone', this.selectedCoupon.id)
        .then(() => {
          this.isShowCloneModal = false
          this.loadingClone = false
          this.getCoupons()
        })
        .catch(() => {
          this.loadingClone = false
        })
    },

    copyLink() {
      this.$refs.shareLink.select()
      this.$refs.shareLink.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    viewResult(coupon) {
      let url = `${this.domainUrl}/pages/${coupon.link}/${this.user.business.id}/result`
      window.open(url, '_blank')
    },

    share(coupon) {
      this.shareLink = '/'
      this.showShareModal = true

      const  { whitelabel } = this.config
      if (this.config.custom_domain && whitelabel && whitelabel.is_ssl_cert_exists && whitelabel.is_vhost_exists) {
        this.shareLink = `https://${this.config.custom_domain}/business/campaigns/${coupon.share_token}`;
      } else {
        this.shareLink = `https://${this.config.subdomain}.${this.config.whitelabel.domain}/business/campaigns/${coupon.share_token}`
      }
    },

    showImport() {
      this.importLink = ''
      this.isShowImport = true
    },

    importCampaign() {
      this.loadingImport = true
      const campaignToken = this.importLink.replace(/.+\/campaigns\//, '')

      this.$store
          .dispatch('coupon/findByUniqueId', campaignToken)
          .then((coupon) => {
              this.loadingImport = false
              this.isShowImport = false
              this.$router.push({ name: 'business.coupons.share', params: {id: campaignToken} })
          })
          .catch(() => {
              this.loadingImport = false
          })
    }
  },
}
</script>
<style scoped>
.upgrade-btn {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #ffc500;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    color: black;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.image {
  background-image: url('~~~@assets/images/loop.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height:120px;
  width: 185.75px;
  display:block;
  margin:0 auto;
}
</style>